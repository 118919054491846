import React from 'react'
import scrollIntoView from 'scroll-into-view'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { updateSyncWarnings, change, untouch } from 'redux-form/dist/redux-form'
import { isAfter, endOfDay } from 'date-fns'
import isString from 'lodash/isString'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import range from 'lodash/range'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import { toast } from 'react-toastify'

import {
  DEFAULT_LANGUAGE,
  defaultFilters,
  defaultPagination,
  ATTRIBUTE_FILTER_PREFIX,
  SMS_SENDER_STATUS_DENIED,
} from '~/common/utils/constants'
import { PLANS_LEVELS, TRIAL_PRIOD_STATUS } from '~/common/utils/constants/providers'
import { checkAlignment, checkDkim } from '~/services/senders'
import { captureFilteredExceptionHandler } from './sentry'
import CustomValidationError from '~/components/CustomValidationError'
import { checkPublicDomain } from '~/services/publicDomain'

export function generateAttributes(data, values, formValues, isEdit) {
  let obj = {}

  if (Array.isArray(data) && !isEmpty(data)) {
    data.forEach((i) => {
      if (typeof values === 'undefined') {
        obj[i.code] =
          typeof get(formValues, `${i.code}`) === 'undefined'
            ? isEdit
              ? undefined
              : false
            : formValues[i.code]
      } else if (values && values.some((attr) => attr === i.code)) {
        obj[i.code] =
          typeof get(formValues, `${i.code}`) === 'undefined' ? true : formValues[i.code]
      } else {
        obj[i.code] =
          typeof get(formValues, `${i.code}`) === 'undefined' ? false : formValues[i.code]
      }
    })
  }

  return obj
}

export function generateListOptions(data, additionalFields = {}, list = [], filter) {
  if (!Array.isArray(data) || isEmpty(data)) return []
  let preparedData = data
  if (list && Array.isArray(list) && !!get(list, 'length')) {
    preparedData = data.filter(
      (dataItem) => !list.some((listItem) => dataItem.hash === listItem.hash)
    )
  }
  const options = preparedData.map((i) => {
    const fields = Object.keys(additionalFields)
      .filter((key) => typeof i[key] !== 'undefined')
      .map((key) => ({ [`${additionalFields[key]}`]: i[key] }))
      .reduce((result, current) => ({ ...result, ...current }), {})

    return { value: i.hash, label: i.name, ...fields }
  })
  if (!filter) {
    return options
  }
  return options.filter(filter)
}

export function getColumnsOptionsFromColumnKeys(columnKeys = []) {
  if (!columnKeys) return []
  const connectOptions = Object.entries(columnKeys[0])
    .map(([key, value]) => {
      if (!isString(value)) {
        return false
      }
      return {
        value: key ? `${key}`.trim() : key,
        label: value ? `${value}`.trim() : value,
      }
    })
    .filter(Boolean)
  return connectOptions
}

export function getColumnsOptions(e) {
  if (!e) return []
  const keys = getColumnsKeys(e)
  const connectOptions = Object.entries(keys[0])
    .map(([key, value]) => {
      if (!isString(value)) {
        return false
      }
      return {
        value: key ? `${key}`.trim() : key,
        label: value ? `${value}`.trim() : value,
      }
    })
    .filter(Boolean)
  return connectOptions
}

export function getColumnsKeys(e) {
  if (!e) return {}
  const paragraph = e.split(/\n/g)
  return paragraph.map((line) => {
    const array = line.split(/[,;\t]/)
    const obj = {}
    array.forEach((i, index) => {
      obj[`field${index}`] = i ? `${i}`.trim() : i
      obj.options = {}
      obj.options.temp = obj[`field${index}`]
      obj.options.error = null
      if (!line.trim()) {
        obj.options.skip = !line.trim()
      }
    })

    return obj
  })
}

export function formatSendAfter(count) {
  if (!count || (!count.days && !count.hours))
    return <FormattedMessage id="send-after.immediately" />
  let days = count.days ? (
    <FormattedMessage id="send-after.days" values={{ count: count.days }} />
  ) : (
    ''
  )
  let hours = count.hours ? (
    <FormattedMessage id="send-after.hours" values={{ count: count.hours }} />
  ) : (
    ''
  )
  if (!days && !hours) return ''
  return (
    <>
      {days}
      {hours ? ' ' : ''}
      {hours}
    </>
  )
}

export function generateHours(hours = 24) {
  return range(hours).map((value) => ({ value, label: value }))
}

export function getStatus(data, slug) {
  if (!data || !data.length) return
  return data.find((i) => i.slug === slug).status
}

export function generateListOptionsObject(data, list) {
  if (!Array.isArray(data) || isEmpty(data)) return []
  if (list && Array.isArray(list)) {
    data = data.filter((dataItem) => !list.some((listItem) => dataItem.hash === listItem.hash))
  }
  return data.map((i) => ({
    value: { hash: i.hash, name: i.name },
    label: i.name,
  }))
}

export function generateSiteOptionsObject(data, site) {
  if (!Array.isArray(data) || isEmpty(data)) return []
  if (site && Array.isArray(site)) {
    data = data.filter((dataItem) => !site.some((siteItem) => dataItem.id === siteItem.id))
  }
  return data.map((i) => ({
    value: i.id,
    label: i.url,
  }))
}

export function generateSenderOptions(data, withId, additionalFields = {}) {
  if (!Array.isArray(data) || isEmpty(data)) {
    return []
  }
  return data.map((i) => {
    const fields = Object.keys(additionalFields)
      .filter((key) => typeof i[key] !== 'undefined' || typeof additionalFields[key] === 'function')
      .map((key) => {
        return typeof additionalFields[key] === 'function'
          ? { [`${key}`]: additionalFields[key](i) }
          : { [`${additionalFields[key]}`]: i[key] }
      })
      .reduce((result, current) => ({ ...result, ...current }), {})
    return {
      value: withId ? i.id : `${i.name}, ${i.email}`,
      label: `${i.name}, ${i.email}`,
      ...fields,
    }
  })
}

export const generateSMSSenderOptions = (data, withId, additionalFields = {}) => {
  if (!Array.isArray(data) || isEmpty(data)) {
    return []
  }
  return data
    .filter((i) => i.status !== SMS_SENDER_STATUS_DENIED)
    .map((i) => {
      const fields = Object.keys(additionalFields)
        .filter(
          (key) => typeof i[key] !== 'undefined' || typeof additionalFields[key] === 'function'
        )
        .map((key) => {
          return typeof additionalFields[key] === 'function'
            ? { [`${key}`]: additionalFields[key](i) }
            : { [`${additionalFields[key]}`]: i[key] }
        })
        .reduce((result, current) => ({ ...result, ...current }), {})
      return {
        value: withId ? i.id : `${i.name}, ${i.email}`,
        label: i.name,
        ...fields,
      }
    })
}

export const splitSenderOption = (option) => {
  const parts = `${option}`.split(', ')
  if (parts.length <= 2) {
    return parts
  }
  return [parts.slice(0, parts.length - 1).join(', '), parts[parts.length - 1]]
}

export function cutString(text = '', quantity) {
  if (text.length > quantity) {
    return `${text.substring(0, quantity)}...`
  }

  return text
}

export function getCheckedLists(objLists) {
  const keys = Object.keys(objLists)
  return keys.filter(function (key) {
    return objLists[key]
  })
}

export function onSubmitFail(errors, ...args) {
  for (let field in errors) {
    const invalidElement = document.querySelector(`[name="${field}"]`)
    if (invalidElement) {
      scrollIntoView(
        invalidElement.type === 'hidden' ? invalidElement.parentElement : invalidElement
      )
    }
  }

  return undefined
}

export function mapChartData(data = {}, formatMessage) {
  const labels = Array.isArray(data.unique) ? data.unique.map((item) => item.end) : []
  const openings = Array.isArray(data.unique) ? data.unique.map((item) => item.count) : []
  const clicks = Array.isArray(data.clicks) ? data.clicks.map((item) => item.count) : []

  labels.unshift(get(data, 'unique[0].start', 0) || 0)
  openings.unshift(0)
  clicks.unshift(0)

  return {
    labels: labels,
    datasets: [
      {
        borderColor: '#38a169',
        label: formatMessage({ id: 'chart.opennings' }),
        data: openings,
        fill: true,
        backgroundColor: 'rgb(56 161 105 / 10%)',
        borderWidth: '1.5',
        pointBackgroundColor: '#DCF4E7',
      },
      {
        borderColor: '#E4B011',
        label: formatMessage({ id: 'chart.click-rate' }),
        data: clicks,
        fill: true,
        backgroundColor: 'rgb(242 201 76 / 10%)',
        borderWidth: '1.5',
        pointBackgroundColor: '#FCEFC6',
      },
    ],
  }
}

const getPort = (url, withPort = false) => {
  const location = url || window.location.hostname
  return location === 'localhost' ? (withPort ? window.location.port : '') : ''
}

export function getOldAppDomain(url, withPort = false) {
  const location = url || window.location.hostname
  const port = getPort(url, withPort)
  const isLocalhost = location === 'localhost'
  return `${isLocalhost ? '' : 'old.'}${location.replace(/^beta./, '').replace(/^old./, '')}${
    port ? `:${port}` : ''
  }`
}

export function getDomain(url, withPort = false) {
  const location = url || window.location.hostname
  const port = getPort(url, withPort)
  return `${location.replace(/^beta./, '').replace(/^old./, '')}${port ? `:${port}` : ''}`
}

export function getCookieDomain(url) {
  const location = url || window.location.hostname
  if (location === 'localhost') {
    return location
  }
  return `.${location.replace(/^beta./, '').replace(/^old./, '')}`
}

export function getPaymentUrl(url) {
  return `${window.location.origin}/${url}`
}

export function getAttributesObject(attributes = {}) {
  const newAttributes = {}
  Object.keys(attributes).forEach((attr) => {
    let newLabel = attr
    newLabel = newLabel
      .replace(/atr_/g, '')
      .replace(/attributes./g, '')
      .trim()
    newAttributes[newLabel] = attributes[attr] || undefined
  })
  return newAttributes
}

export function getAttributesObjectFromRow(attributes = {}, row = {}) {
  const newAttributes = {}
  Object.keys(attributes).forEach((attr) => {
    let newLabel = attr
    newLabel = newLabel
      .replace(/atr_/g, '')
      .replace(/attributes./g, '')
      .trim()
    newAttributes[newLabel] = row[attributes[attr]] || undefined
  })
  return newAttributes
}

export function transactionsType(type) {
  switch (type) {
    case 1:
      return <FormattedMessage id="transaction-type.registration" />
    case 2:
      return <FormattedMessage id="transaction-type.free-to-paid" />
    case 3:
      return <FormattedMessage id="transaction-type.paid-to-larger-paid" />
    case 4:
      return <FormattedMessage id="transaction-type.recurring" />
    case 5:
      return <FormattedMessage id="transaction-type.downgrade" />
    default:
      return <FormattedMessage id="common.no" />
  }
}

export function checkAccountStatus(profile) {
  return (
    get(profile, 'data.subscription.is_contact_based', true) &&
    get(profile, 'data.subscription.is_free', true)
  )
}

export function getTrialPeriodStatus(profile) {
  const trial = get(profile, 'data.trial')
  if (!trial) return TRIAL_PRIOD_STATUS.NOT_USED

  const trialEndDate = get(trial, 'end_date', '')
  const trialUpgradeDate = get(trial, 'upgrade_date', '')
  const isInTrialPeriod =
    !!trialEndDate && !trialUpgradeDate && isAfter(endOfDay(new Date(trialEndDate)), new Date())

  if (isInTrialPeriod) {
    return TRIAL_PRIOD_STATUS.USING
  } else {
    return TRIAL_PRIOD_STATUS.USED
  }
}

export function getPlanLevelWithTrial(profile) {
  const trialPlanLevel = get(profile, 'data.trial.plan_level') || PLANS_LEVELS.START
  const subscriptionPlanLevel = get(profile, 'data.subscription.plan_level') || PLANS_LEVELS.START

  if (getTrialPeriodStatus(profile) !== TRIAL_PRIOD_STATUS.USING) return subscriptionPlanLevel
  return Math.max(trialPlanLevel, subscriptionPlanLevel)
}

export function tick(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

export const getUserLanguage = () => {
  const language = window.navigator.language
  if (!language) {
    return DEFAULT_LANGUAGE
  }
  if (language.includes('-')) {
    const languageParts = language.split('-')
    if (!languageParts[0]) {
      return DEFAULT_LANGUAGE
    }
    return languageParts[0]
  }
  return language
}

export const calculatePriceData = ({
  article,
  profile,
  address,
  billing,
  discount,
  upgradeConditions,
}) => {
  const isYear = get(article, 'months', 1) === 12
  const discountMonth = get(article, 'start') === 0 ? get(discount, 'data.order_price', 0) : 0
  const discountSum = Math.abs(get(upgradeConditions, 'data.total_discount', 0))
  const planDiscount = Math.abs(get(upgradeConditions, 'data.discount.value', 0))
  const promoDiscount = Math.abs(get(upgradeConditions, 'data.promo_discount.value', 0))
  const trialDiscount = Math.abs(get(upgradeConditions, 'data.trial_discount.value', 0))
  const trialNotUsedDays = Math.abs(get(upgradeConditions, 'data.trial_discount.days', 0))

  const isInvoiceDeliveryByPost = get(profile, 'data.invoice_delivery') === 1
  const country = get(profile, 'data.different_billing_address')
    ? get(billing, 'data.country', 'SE')
    : get(address, 'data.country', 'SE')
  const isCountrySupportDeliveryByPost = ['SE'].indexOf(country) !== -1
  const currency = get(profile, 'data.currency_code', 'SEK')
  const isAllowedCurrency = ['SEK'].indexOf(currency) !== -1
  const isShowAdditionalPostFee =
    isInvoiceDeliveryByPost && isYear && isCountrySupportDeliveryByPost && isAllowedCurrency

  const vatPercent = get(upgradeConditions, 'data.user_vat_percent', 0) * 100

  const currentPostDeliveryPrice = get(upgradeConditions, 'data.invoice_postal_delivery_price', 0)

  const articleVat = get(upgradeConditions, 'data.invoice_vat_value', 0)
  const invoicePrice = get(upgradeConditions, 'data.invoice_price', 0)
  const articleSumWithDiscount = invoicePrice - articleVat

  const conversionValue = currency === 'SEK' ? articleSumWithDiscount : articleSumWithDiscount * 10

  return {
    isYear,
    discountMonth,
    discountSum,
    planDiscount,
    trialDiscount,
    trialNotUsedDays,
    promoDiscount,
    currency,
    isShowAdditionalPostFee,
    articleSumWithDiscount,
    articleVat,
    conversionValue,
    currentPostDeliveryPrice,
    invoicePrice,
    vatPercent,
  }
}

export const prepareUniqueByField = (field) => (a, b) => {
  return uniqBy([...(Array.isArray(a) ? a : []), ...(Array.isArray(b) ? b : [])], field)
}

export const prepareUniqueByHash = prepareUniqueByField('hash')
export const prepareUniqueById = prepareUniqueByField('id')

const getCheckPromise = async (sender, id) => {
  if (sender?.is_authenticated === null) {
    try {
      await checkAlignment(id)
    } catch (e) {
      captureFilteredExceptionHandler(e)
    }
  }
  let result = { authenticated: false }
  if (sender?.is_authenticated !== false) {
    try {
      result = await checkDkim(id)
    } catch (e) {
      captureFilteredExceptionHandler(e)
    }
  }
  return result
}

export const resetFields = (dispatch, formName, fieldsObj) => {
  Object.keys(fieldsObj).forEach((fieldKey) => {
    //reset the field's value
    dispatch(change(formName, fieldKey, fieldsObj[fieldKey]))
    //reset the field's error
    dispatch(untouch(formName, fieldKey))
  })
}

export const createdSorter = (item) => item.created || new Date().toISOString()

export const isEqualByIdKey = (namespace) => (a, b) => {
  if (namespace === 'lists') return isEqual(a['hash'], b['hash'])
  return isEqual(a, b)
}

export const hashCode = (s) => {
  return s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}

export const itemsWithCount = (item) => !!get(item, 'count')

export const processSubmitErrors = (submitErrors = {}) => {
  const errors = {
    ...submitErrors,
  }
  if (get(errors, 'missing_contact_balance')) {
    errors['missing_contact_balance'] = <FormattedMessage id="extracontacts.title" />
  }
  if (get(errors, 'missing_email_balance')) {
    errors['missing_email_balance'] = <FormattedMessage id="extramail.title" />
  }
  return errors
}

const customDefaultFilters = {
  invoices: {
    paginate_by: 12,
  },
}

export const prepareFilters = (filters, namespace) =>
  Object.keys(filters)
    .filter((key) => {
      if (key === 'page' && filters[key] === 1) {
        return false
      }
      const customFilterValue = get(customDefaultFilters, `${namespace}.${key}`)
      if (typeof customFilterValue !== 'undefined' && filters[key] === customFilterValue) {
        return false
      }
      if (key === 'paginate_by' && filters[key] === defaultPagination) {
        return false
      }
      return true
    })
    .filter((key) => {
      const defaultCurrentFilters = defaultFilters[namespace]
      if (!defaultCurrentFilters) {
        return true
      }
      if (defaultCurrentFilters[key] === filters[key]) {
        return false
      }
      return true
    })
    .reduce((result, current) => ({ ...result, [current]: filters[current] }), {})

export const getNoOptionsMessage = ({ itemsType, itemCreatePath, optionsLength }) => {
  if (!optionsLength && itemCreatePath) {
    const { state, ...path } = itemCreatePath
    return (
      <FormattedMessage
        id="common.select.noOptions.create"
        values={{
          itemsType: itemsType || 'items',
          link: (
            <Link to={path} state={state}>
              <FormattedMessage id="common.select.create.one" />
            </Link>
          ),
        }}
      />
    )
  }
  return <FormattedMessage id="common.select.noOptions" />
}

export const prepareStripeErrorMessage = (formatMessage, error) => {
  if (get(error, 'code') === 'payment_intent_authentication_failure') {
    return formatMessage({
      id: 'stripe.error.payment_intent_authentication_failure',
    })
  }
  if (get(error, 'code') === 'setup_intent_authentication_failure') {
    return formatMessage({
      id: 'stripe.error.setup_intent_authentication_failure',
    })
  }
  if (get(error, 'code') === 'stolen_card') {
    return formatMessage({
      id: 'stripe.error.default',
    })
  }
  if (get(error, 'message')) {
    return get(error, 'message')
  }
  return formatMessage({ id: 'stripe.error.default' })
}

export const prepareInvoicePaymentStatus = (invoice) => {
  const isOverpaid = invoice.balance < 0 && invoice.total_amount > 0
  if (invoice.payment !== 'Not Paid' && !isOverpaid) {
    if (invoice.payment === 'Payment not needed') {
      return <FormattedMessage id="invoices.paymentNotNeeded" />
    } else if (`${invoice.payment}`.indexOf('Paid ') === 0) {
      return (
        <FormattedMessage
          id="invoices.paidWithDate"
          values={{
            date: `${invoice.payment}`.split('Paid ')[1],
          }}
        />
      )
    }
  }
  return <span>{invoice.payment}</span>
}

export const prepareInvoiceComment = (invoice) => {
  if (`${invoice.note}`.indexOf('Credits invoice no ') === 0) {
    return (
      <FormattedMessage
        id="invoices.creditsInvoice"
        values={{
          invoice: (`${invoice.note}`.split('Credits invoice no ') || [])[1],
        }}
      />
    )
  } else if (`${invoice.note}`.indexOf('Credited by invoice no ') === 0) {
    return (
      <FormattedMessage
        id="invoices.creditedByInvoice"
        values={{
          invoice: (`${invoice.note}`.split('Credited by invoice no ') || [])[1],
        }}
      />
    )
  }
  return <span>{invoice.note}</span>
}

export const isRouteMatch = (route, matchList) => {
  for (let i = 0, l = matchList.length; i < l; i += 1) {
    if (route.startsWith(matchList[i])) {
      return true
    }
  }

  return false
}

export const prepareFormPageFilter = (value) => {
  let preparedValue = {
    whitelist: [],
  }
  if (typeof value === 'string') {
    try {
      preparedValue = JSON.parse(value)
      if (!get(preparedValue, 'whitelist') || !Array.isArray(get(preparedValue, 'whitelist'))) {
        preparedValue.whitelist = []
      }
    } catch (e) {
      console.error(e)
    }
  } else if (get(value, 'whitelist')) {
    preparedValue = value
  }
  if (!Array.isArray(get(preparedValue, 'whitelist'))) {
    preparedValue.whitelist = []
  }
  preparedValue.whitelist = preparedValue.whitelist.filter(Boolean)
  return preparedValue
}

const defaultShouldAsyncValidate = function defaultShouldAsyncValidate(_ref) {
  const initialized = _ref.initialized,
    trigger = _ref.trigger,
    pristine = _ref.pristine,
    syncValidationPasses = _ref.syncValidationPasses

  if (!syncValidationPasses) {
    return false
  }

  switch (trigger) {
    case 'blur':
    case 'change':
      // blurring
      return true

    case 'submit':
      // submitting, so only async validate if form is dirty or was never initialized
      // conversely, DON'T async validate if the form is pristine just as it was initialized
      return !pristine || !initialized

    default:
      return false
  }
}

export const customShouldAsyncValidate = (params) => {
  return defaultShouldAsyncValidate({
    ...params,
    syncValidationPasses: true,
  })
}

export const isIE = () => {
  const ua = window.navigator.userAgent
  const isIE = /MSIE|Trident/.test(ua)

  if (isIE) {
    return true
  }
  return false
}

export const encodeParam = (value) => `${value}`.replace(/%/g, '%25')

export const getValueOrDefault = (value, defaultValue, keepEmpty = false) => {
  const parts = `${value}`.split('-') || []
  if (parts[0] === defaultValue && parts.length > 1) {
    return <FormattedMessage id={defaultValue} />
  }
  if (keepEmpty) {
    return defaultValue === value ? <FormattedMessage id={defaultValue} /> : value
  }
  return defaultValue === value || !value || (value && !`${value}`.trim()) ? (
    <FormattedMessage id={defaultValue} />
  ) : (
    value
  )
}

const hasSections = (body) => {
  return (
    body?.columns &&
    body?.columns?.length > 0 &&
    body?.columns[0] &&
    body?.columns[0]?.items &&
    body?.columns[0]?.items?.length > 0
  )
}

export const getBlocks = (body) => {
  if (!hasSections(body)) {
    return []
  }

  const sections = body.columns[0].items

  if (!sections || !sections.length) return []

  const columns = sections.map((item) => item.columns).reduce((acc, columns) => acc.concat(columns))

  if (!columns || !columns.length) return []

  const blocks = columns.map((column) => column.items).reduce((acc, items) => acc.concat(items), [])
  return [...blocks, ...sections.map((section) => ({ ...section, columns: [] }))]
}

export const filterBlocksWithImages = (blocks) => {
  return blocks.filter((item) => {
    if (item.type === 'IMAGE' && !!item?.settings?.imageUrl) {
      return true
    }
    if (
      item.type === 'SECTION' &&
      item?.settings?.hasBackgroundImage &&
      !!item?.settings?.backgroundImageUrl
    ) {
      return true
    }
    return false
  })
}

export const getBlockImage = (block) => {
  if (block?.type === 'IMAGE') {
    return block?.settings?.imageUrl
  }
  if (block?.type === 'SECTION') {
    return block?.settings?.backgroundImageUrl
  }
  return ''
}

export const checkFilteringByAttribute = (filters = {}) => {
  return !!Object.keys(filters).find((filterKey) => filterKey.startsWith(ATTRIBUTE_FILTER_PREFIX))
}

export const checkFiltering = (filters = {}, filterKeyList = []) => {
  return !!Object.keys(filters).find(
    (key) => filterKeyList.includes(key) || key.startsWith(ATTRIBUTE_FILTER_PREFIX)
  )
}

export const displayToastError = (error) => {
  Object.keys(error).forEach((key) => {
    if (typeof error[key] === 'object' && !!error[key]) {
      displayToastError(error[key])
    } else {
      toast.error(<CustomValidationError messages={error[key]} />)
    }
  })
}

export const isValueEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  )
}

export const isEqualConsideringEmpty = (a, b) => {
  if (isValueEmpty(a) && isValueEmpty(b)) return true
  if (Array.isArray(a) && Array.isArray(b)) return isEqual(a, b)
  return a === b
}

export const isSenderNotAuthenticated = (sender) =>
  !sender.is_authenticated && !checkPublicDomain(sender.email)

export const asyncValidateSenderDkim = (field, collection = null, key = 'id') => (
  values,
  dispatch,
  props
) => {
  if (values[field]) {
    const sender = !collection
      ? values
      : (get(props, `${collection}`) || []).find((i) => i[key] === values[field]) || {}
    const id = !collection ? sender[field] : sender[key]
    return getCheckPromise(sender, id).then(() => {
      const isNotAuthenticated = isSenderNotAuthenticated(sender)
      if (isNotAuthenticated) {
        dispatch(
          updateSyncWarnings(props.form, {
            [`${field}`]: (
              <>
                <strong>
                  <FormattedMessage id="helptext.dkim.tip" />
                  &nbsp;
                </strong>
                <em>
                  <FormattedMessage
                    id="helptext.dkim.description"
                    values={{ email: sender.email, gan: sender.from_preview }}
                  />
                  &nbsp;
                  <a
                    href="https://support.getanewsletter.com/articles/3752766"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <FormattedMessage id="helptext.dkim.link" />
                    </span>
                  </a>
                </em>
              </>
            ),
          })
        )
      } else {
        dispatch(
          updateSyncWarnings(props.form, {
            [`${field}`]: null,
          })
        )
      }
      return true
    })
  }

  return new Promise((resolve) => resolve(true))
}

export const checkSendersDns = (sendersArray) => {
  if (!sendersArray || !Array.isArray(sendersArray)) return false
  return sendersArray.some((sender) => isSenderNotAuthenticated(sender))
}

export const getSMSUnsubscribeText = () => {
  return `\nStop? https://${import.meta.env.REACT_APP_LINK_SHORTENER_DOMAIN}/u/hash`
}

export const getProviderName = (provider) => {
  switch (provider) {
    case 'file':
      return 'import.file.overview.history.upload.title'
    case 'copy-paste':
      return 'import.providers.name.copy-paste'
    case 'fortnox':
      return 'import.providers.name.fortnox'
    default:
      return 'import.providers.name.copy-paste'
  }
}

export const prepareFortnoxFiltersForRequest = (filters = []) => {
  return filters
    .filter((filter) => filter.value.trim() !== '')
    .map((filter) => {
      return {
        name: filter.attribute,
        operator: filter.attribute === 'status' ? 'eq' : 'contain',
        value: filter.value,
      }
    })
}

export const shortenSmsLink = (message = '') => {
  return message.replace(
    // /https?:\/\/[^\s,;]+/g,
    /(?:(?<=\s)|(?<=^))(?:https?:\/\/|www\.)[^\s,;]+/g,
    () => `https://${import.meta.env.REACT_APP_LINK_SHORTENER_DOMAIN}/<hash>`
  )
}

export const getDecimalScale = (value) => {
  const numericValue = parseFloat(value)
  if (Number.isInteger(numericValue)) {
    return 0
  }
  return 2
}
