import React from 'react'
import { Outlet, Route } from 'react-router-dom'

import AsyncComponent from '~/components/AsyncComponent'

const IndexPageComponent = () => import('./IndexPage')
const IndexPage = AsyncComponent({ component: IndexPageComponent })

const ImportFromFileContainerComponent = () => import('./ImportContacts/ImportFromFile')
const ImportFromFileContainer = AsyncComponent({ component: ImportFromFileContainerComponent })

const IndexNewImportPageComponent = () => import('./ImportContactsNew/IndexPage')
const IndexNewImportPage = AsyncComponent({ component: IndexNewImportPageComponent })

const ImportFromFileNewDestinationComponent = () =>
  import('./ImportContactsNew/ImportFromFile/Destination')
const ImportFromFileNewDestination = AsyncComponent({
  component: ImportFromFileNewDestinationComponent,
})
const ImportFromFileMatchComponent = () => import('./ImportContactsNew/ImportFromFile/MatchColumns')
const ImportFromFileMatch = AsyncComponent({
  component: ImportFromFileMatchComponent,
})

const ImportFromFileNewUploadComponent = () => import('./ImportContactsNew/ImportFromFile/Upload')
const ImportFromFileNewUpload = AsyncComponent({ component: ImportFromFileNewUploadComponent })

const ImportFromFileReviewNewComponent = () => import('./ImportContactsNew/ImportFromFile/Review')
const ImportFromFileReviewNew = AsyncComponent({ component: ImportFromFileReviewNewComponent })

const ImportFromFileNewSuccessComponent = () => import('./ImportContactsNew/ImportFromFile/Success')
const ImportFromFileNewSuccess = AsyncComponent({ component: ImportFromFileNewSuccessComponent })

const ImportFromCopyPasteNewDestinationComponent = () =>
  import('./ImportContactsNew/ImportFromCopyPaste/Destination')
const ImportFromCopyPasteNewDestination = AsyncComponent({
  component: ImportFromCopyPasteNewDestinationComponent,
})

const ImportFromCopyPasteNewUploadComponent = () =>
  import('./ImportContactsNew/ImportFromCopyPaste/Upload')
const ImportFromCopyPasteNewUpload = AsyncComponent({
  component: ImportFromCopyPasteNewUploadComponent,
})

const ImportFromCopyPasteMatchComponent = () =>
  import('./ImportContactsNew/ImportFromCopyPaste/MatchColumns')
const ImportFromCopyPasteMatch = AsyncComponent({
  component: ImportFromCopyPasteMatchComponent,
})

const ImportFromCopyPasteReviewComponent = () =>
  import('./ImportContactsNew/ImportFromCopyPaste/Review')
const ImportFromCopyPasteReview = AsyncComponent({
  component: ImportFromCopyPasteReviewComponent,
})

const ImportFromCopyPasteSuccessComponent = () =>
  import('./ImportContactsNew/ImportFromCopyPaste/Success')
const ImportFromCopyPasteSuccess = AsyncComponent({
  component: ImportFromCopyPasteSuccessComponent,
})

const ImportFromFortnoxDestinationComponent = () =>
  import('./ImportContactsNew/ImportFromFortnox/Destination')
const ImportFromFortnoxDestination = AsyncComponent({
  component: ImportFromFortnoxDestinationComponent,
})

const ImportFromFortnoxFilterComponent = () =>
  import('./ImportContactsNew/ImportFromFortnox/Filter')
const ImportFromFortnoxFilter = AsyncComponent({
  component: ImportFromFortnoxFilterComponent,
})

const ImportFromFortnoxMatchComponent = () =>
  import('./ImportContactsNew/ImportFromFortnox/MatchColumns')
const ImportFromFortnoxMatch = AsyncComponent({
  component: ImportFromFortnoxMatchComponent,
})

const ImportFromFortnoxReviewComponent = () =>
  import('./ImportContactsNew/ImportFromFortnox/Review')
const ImportFromFortnoxReview = AsyncComponent({
  component: ImportFromFortnoxReviewComponent,
})

const ImportFromFortnoxSuccessComponent = () =>
  import('./ImportContactsNew/ImportFromFortnox/Success')
const ImportFromFortnoxSuccess = AsyncComponent({
  component: ImportFromFortnoxSuccessComponent,
})

const ConnectProviderContainerComponent = () => import('./ImportContacts/ConnectProvider')
const ConnectProviderContainer = AsyncComponent({ component: ConnectProviderContainerComponent })

const ContactPageComponent = () => import('./ContactPage')
const ContactPage = AsyncComponent({ component: ContactPageComponent })

export const contactsRoutes = (
  <>
    <Route exact index element={<IndexPage key="index" />} />
    <Route exact path=":id" element={<ContactPage />} />

    <Route path="import" element={<Outlet />}>
      <Route exact index element={<IndexNewImportPage />} />

      <Route path="text" element={<Outlet />}>
        <Route
          exact
          path="destination"
          element={<ImportFromCopyPasteNewDestination key="destination" />}
        />
        <Route
          exact
          path=":hash/destination"
          element={<ImportFromCopyPasteNewDestination key="destination" />}
        />
        <Route exact path="upload" element={<ImportFromCopyPasteNewUpload key="upload" />} />
        <Route exact path=":hash/upload" element={<ImportFromCopyPasteNewUpload key="upload" />} />
        <Route exact path=":hash/match" element={<ImportFromCopyPasteMatch key="match" />} />
        <Route exact path=":hash/review" element={<ImportFromCopyPasteReview key="review" />} />
        <Route exact path=":hash/success" element={<ImportFromCopyPasteSuccess key="success" />} />
      </Route>

      <Route exact path="fortnox" element={<Outlet />}>
        <Route
          exact
          path="destination"
          element={<ImportFromFortnoxDestination key="destination" />}
        />
        <Route exact path="filter" element={<ImportFromFortnoxFilter key="filter" />} />
        <Route exact path="match" element={<ImportFromFortnoxMatch key="match" />} />
        <Route exact path="review" element={<ImportFromFortnoxReview key="review" />} />
        <Route exact path=":hash/success" element={<ImportFromFortnoxSuccess key="success" />} />
      </Route>

      <Route exact path="connect/:slug" element={<ConnectProviderContainer />} />

      <Route path="file" element={<Outlet />}>
        <Route exact path=":hash" element={<ImportFromFileContainer key="hash" />} />

        <Route
          exact
          path="destination"
          element={<ImportFromFileNewDestination key="destination" />}
        />
        <Route
          exact
          path=":hash/destination"
          element={<ImportFromFileNewDestination key="destination" />}
        />
        <Route exact path="upload" element={<ImportFromFileNewUpload key="upload" />} />
        <Route exact path=":hash/upload" element={<ImportFromFileNewUpload key="upload" />} />
        <Route exact path=":hash/match" element={<ImportFromFileMatch key="match" />} />
        <Route exact path=":hash/review" element={<ImportFromFileReviewNew key="review" />} />
        <Route exact path=":hash/success" element={<ImportFromFileNewSuccess key="success" />} />
      </Route>
    </Route>
  </>
)

export const subscriptionsRoutes = (
  <>
    <Route exact index element={<IndexPage key="subscriptions" />} />
  </>
)

export const listsRoutes = (
  <>
    <Route exact index element={<IndexPage key="lists-index" />} />
    <Route exact path="edit/:hash" element={<IndexPage key="lists-edit-hash" />} />
  </>
)

export const sitesRoutes = (
  <>
    <Route exact index element={<IndexPage key="sites-index" />} />
    <Route exact path="edit/:id" element={<IndexPage key="sites-edit-id" />} />
  </>
)

export const attributesRoutes = (
  <>
    <Route exact index element={<IndexPage key="attributes" />} />
  </>
)

export const formsRoutes = (
  <>
    <Route exact index element={<IndexPage key="forms" />} />
  </>
)

export const pagesRoutes = (
  <>
    <Route exact index element={<IndexPage key="pages" />} />
  </>
)

export const surveysRoutes = (
  <>
    <Route exact index element={<IndexPage key="surveys" />} />
  </>
)
